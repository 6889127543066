


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { tasksStore, teamsStore } from '@/store'

const enum DisplayMode {
  SELECTOR_LIST = 'selector-list',
  SELECTOR_BTN_TOGGLE = 'selector-btn-toggle',
  SELECTOR_INPUT = 'selector-input'
}

/**
 * yup, this is almost a duplicate from /components/UI/TaskImportanceSelector
 * why? because the 'original' one already has too many 'ifs'
 * this component is functionally almost identical, but visually different
 * the only reused visual bit is btn-toggle selector
 */
@Component({
  name: 'TaskImportanceSelector',

  components: {
    SelectorBtnToggle: () => import('./SelectorBtnToggle.vue'),
    SelectorInput: () => import('./SelectorInput.vue'),
    SelectorList: () => import('./SelectorList.vue'),
  },
})
export default class TaskImportanceSelector extends Vue {
  /**
   * Importance value, updated by the component
   */
  @Prop({
    type: Number,
    default: null,
  }) readonly value!: number | null

  /**
   * Number of options, from which to start using input type
   * instead of btn-toggle (and, possibly list)
   */
  @Prop({
    type: Number,
    default: 5,
  }) readonly displayButtonsThreshold!: number

  /**
   * Hide caption below selector if necessary.
   */
  @Prop({
    type: Boolean,
    default: false,
  }) readonly hideCaption!: boolean

  get displayMode (): DisplayMode {
    if (tasksStore.getters.importanceUseIcons) {
      return DisplayMode.SELECTOR_LIST
    }

    if (
      tasksStore.getters.importanceOptions.length <=
      this.displayButtonsThreshold
    ) {
      return DisplayMode.SELECTOR_BTN_TOGGLE
    }

    return DisplayMode.SELECTOR_INPUT
  }

  get hideCaptionComputed (): boolean {
    return (
      this.displayMode === DisplayMode.SELECTOR_INPUT || // already has caption in :hint
      this.displayMode === DisplayMode.SELECTOR_LIST || // list is self-descriptive
      this.hideCaption
    )
  }

  get caption (): string {
    return teamsStore.getters.currentTeam.taskImportanceRev
      ? this.$t('tasks.importanceCaption.descending').toString()
      : this.$t('tasks.importanceCaption.ascending').toString()
  }
}
